<template>
  <div id="head">
    <div><a href="/"><img alt="Vue logo" src="./assets/dice.png" width="100"></a></div>
    <div><p>Zum Streichen auf "-" klicken (-1), für ein neues Spiel, die <a href="/" class="reload">Seite neu laden</a>.</p></div>
  </div>
  <HelloWorld msg="Kniffel"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
body{background:#222;}
a{color:white;}
#head{display:flex;}
#head > div:first-child{width:40%;}
#head > div:last-child{width:50%;align-self:center;}
#app {
  padding:1rem 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}
</style>
