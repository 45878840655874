<template>
  <div id="kniffel">
    <form action="">
      <dl id="top">
        <dt>Einser </dt><dd><vue-number-input :model-value="0" v-model="field1" :min="-1"  :max="5" :step="1" @change="zs()" inline controls /></dd>
        <dt>Zweier </dt><dd><vue-number-input :model-value="0" v-model="field2" :min="-1" :max="10" :step="2" @change="zs()" inline controls /></dd>
        <dt>Dreier </dt><dd><vue-number-input :model-value="0" v-model="field3" :min="-1" :max="15" :step="3" @change="zs()" inline controls /></dd>
        <dt>Vierer </dt><dd><vue-number-input :model-value="0" v-model="field4" :min="-1" :max="20" :step="4" @change="zs()" inline controls /></dd>
        <dt>Fünfer </dt><dd><vue-number-input :model-value="0" v-model="field5" :min="-1" :max="25" :step="5" @change="zs()" inline controls /></dd>
        <dt>Sechser</dt><dd><vue-number-input :model-value="0" v-model="field6" :min="-1" :max="30" :step="6" @change="zs()" inline controls /></dd>
      </dl>
      <dl>
        <dt>Zwischensumme</dt><dd><h4>{{zs()}}</h4></dd>
        <dt>Bonus</dt><dd><h4>{{bonus()}} {{bonus_missing()}}</h4></dd>
        <dt>Summe oben</dt><dd><h3>{{sum_top()}}</h3></dd>
      </dl>
      <dl id="bottom">
        <dt>Dreierpasch</dt><dd><vue-number-input v-model="pasch3" :min="-1" :max="30" @change="zs()" inline controls /></dd>
        <dt>Viererpasch</dt><dd><vue-number-input v-model="pasch4" :min="-1" :max="30" @change="zs()" inline controls /></dd>
        <dt>Full House</dt><dd><vue-number-input v-model="full" :min="-1" :max="25" :step="25" @change="zs()" inline controls /></dd>
        <dt>Kleine Straße</dt><dd><vue-number-input v-model="street_small" :min="-1" :max="30" :step="30" @change="zs()" inline controls /></dd>
        <dt>Große Straße</dt><dd><vue-number-input v-model="street_big" :min="-1" :max="40" :step="40" @change="zs()" inline controls /></dd>
        <dt>Flush</dt><dd><vue-number-input v-model="flush" :min="-1" @change="zs()" :max="50" :step="50" inline controls /></dd>
        <dt>Chance</dt><dd><vue-number-input v-model="chance" :min="-1" :max="30" @change="zs()" inline controls /></dd>
      </dl>
      <dl id="sums">
        <dt>Summe oben</dt><dd><h3>{{sum_top()}}</h3></dd>
        <dt>Summe unten</dt><dd><h3>{{sum_bottom()}}</h3></dd>
      </dl>
      <dl id="sum">
        <dt>Summe</dt><dd><h2>{{sum()}}</h2></dd>
      </dl>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Kniffel',
  props: {
    msg: String
  },
  data() {
    return {
      field1: 0,
      field2: 0,
      field3: 0,
      field4: 0,
      field5: 0,
      field6: 0,
      pasch3: 0,
      pasch4: 0,
      full: 0,
      street_small: 0,
      street_big: 0,
      flush: 0,
      chance: 0,
    };
  },
  methods: {
    zs: function(){
      let field1=this.field1; if(this.field1==-1){field1=0}
      let field2=this.field2; if(this.field2==-1){field2=0}
      let field3=this.field3; if(this.field3==-1){field3=0}
      let field4=this.field4; if(this.field4==-1){field4=0}
      let field5=this.field5; if(this.field5==-1){field5=0}
      let field6=this.field6; if(this.field6==-1){field6=0}
      return Number(field1) + Number(field2) + Number(field3) + Number(field4) + Number(field5) + Number(field6);
    },
    bonus: function(){
      let bonus = 0;
      if(this.zs() > 62) bonus = 35;
      return Number(bonus);
    },
    bonus_missing: function(){
      let bonus_missing = 0;
      if(this.zs() < 63) bonus_missing = 63 - this.zs();
      else bonus_missing = false;
      if(bonus_missing) return '/ '+Number(bonus_missing);
    },
    sum_top: function(){
      return this.bonus() + this.zs();
    },
    sum_bottom: function(){
      let pasch3=this.pasch3; if(this.pasch3==-1){pasch3=0}
      let pasch4=this.pasch4; if(this.pasch4==-1){pasch4=0}
      let full=this.full; if(this.full==-1){full=0}
      let street_small=this.street_small; if(this.street_small==-1){street_small=0}
      let street_big=this.street_big; if(this.street_big==-1){street_big=0}
      let flush=this.flush; if(this.flush==-1){flush=0}
      let chance=this.chance; if(this.chance==-1){chance=0}
      return Number(pasch3) + Number(pasch4) + Number(full) + Number(street_small) + Number(street_big) + Number(flush) + Number(chance);
    },
    sum: function(){
      return Number(this.sum_top()) + Number(this.sum_bottom());
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
h2,h3,h4{margin:0;}
dl{border-bottom:1px solid white;padding-bottom:1rem;display:flex;flex-wrap:wrap;}
dl#sum{border:0;}
dt, dd{width:50%;margin:10px 0;}
dt{width:40%;display:inline;align-self:center;line-height:1;}
input{box-sizing:border-box;}
#kniffel input{text-align:center !important;width:100% !important;}
#sums{padding-bottom:32px;}
#sum{position:fixed;bottom:0;left:0;right:0;background:#222;z-index:9999;margin:0;box-shadow:0px -3px 3px 3px #555;padding-bottom:0;}
</style>
