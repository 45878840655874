import { createApp } from 'vue';
import App from './App.vue';
import VueNumberInput from '@chenfengyuan/vue-number-input';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fab)

const app = createApp(App);
app.component(VueNumberInput.name, VueNumberInput);
app.mount('#app');